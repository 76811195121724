.mobview {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: var(--mui-palette-base-black);
  top: 0;
  left: 0;
  z-index: 9999;

  &__header {
    height: 10%;
    border-bottom: 1px solid var(--mui-palette-base-white);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 4rem;
    &-title {
      font-size: 1.8rem !important;
      font-weight: bold !important;
      color: var(--mui-palette-base-white) !important;
    }
  }
  &__body {
    height: 65%;
    text-align: center;
    video {
      width: 100% !important;
      height: 100% !important;
    }
  }
  &__footer {
    height: 20%;
  }

  &__delete {
    color: var(--mui-palette-base-white) !important;
    font-weight: bold !important;
    font-size: 1.6rem !important;
  }
}
