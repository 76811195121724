.base-dropdown {
  position: relative;
  font-size: 1.4rem;
  height: 4rem;
  min-width: min(100%, 20rem);

  &__main {
    position: absolute;
    background-color: var(--mui-palette-background-primary);
    border: 1px solid var(--mui-palette-borders-primary);
    padding: 10px 8px;
    border-radius: 0.5rem;
    top: 0;
    inset-inline-start: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 1rem;
    cursor: pointer;

    &-placeholder {
      white-space: nowrap;
      width: 85%;
      text-overflow: ellipsis;
      font-size: 1.4rem !important;
      overflow: hidden;
      font-weight: 500 !important;
      color: var(--mui-palette-grayDark-color);
      opacity: 0.52;
      // opacity: 1;
      @media screen and (max-width: 60rem) {
        font-size: 1.3rem !important;
      }
    }
    &--error {
      background-color: var(--mui-palette-base-danger);
      border: 1px solid var(--mui-palette-base-dangerBorder);
    }
    &--disabled {
      background-color: var(--mui-palette-background-colorSoft2);
      border: 1px solid var(--mui-palette-graySoft-color);
    }
  }

  &__error {
    position: absolute;
    top: 4rem;
    width: 100%;
    inset-inline-end: 0;
  }

  &__list {
    position: absolute;
    top: 5rem;
    width: 100%;
    inset-inline-end: 0;
    height: 18rem;
    overflow-y: scroll;
    background-color: var(--mui-palette-base-white);
    border: 1px solid var(--mui-palette-borders-primary);
    border-radius: 5px;
    padding-block: 1rem;
    transform: translateY(-3rem);
    opacity: 0;
    transition: all 0.3s ease;
    display: none;
    z-index: 9;

    &--ignore-stacking-context {
      position: fixed;
    }

    &--open {
      display: block;
      transform: translateY(0px);
      opacity: 1;
      // z-index: 2;
      animation: slideDown 1s ease forwards;
    }
  }
  &__item {
    padding: 0.5rem 1rem;
    transition: all 0.5s ease;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      background-color: var(--mui-palette-background-primary);
    }
    &--selected {
      background-color: var(--mui-palette-primary-colorSoft7);
    }
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    &-icon path {
      fill: var(--mui-palette-primary-colorSoft3);
    }
  }

  &__pickup {
    &-list {
      display: flex;
      gap: 1rem;
      flex-wrap: wrap;
      transform: translateY(1rem);
    }
    &-item {
      color: var(--mui-palette-grayDark-color);
      background-color: var(--mui-palette-base-white);
      border: 1px solid var(--mui-palette-borders-primary);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 5px 5px 4.5rem;
      border-radius: 5px;
      position: relative;
      font-size: 1.4rem;
    }
    &-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      inset-inline-end: 5px;
      cursor: pointer;
    }
  }
}
